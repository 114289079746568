import { gql, useQuery } from '@apollo/client'
import { FC } from 'react';
import { SearchSelectInput } from 'components/core';

interface PlotPickerContainerProps {
  filter?: {
    region?: string;
    district?: string;
    block?: string;
    round?: string;
  };
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any
  setFieldValue: any;
  setFieldTouched: any;
  setFieldError: any;
  rawId?: boolean;
}

const GET_PLOTS = gql`
  query GetPlotsSelect($region: ID $district: ID $block: ID $round: ID) {
    plots: getPlots(region: $region district: $district block: $block round: $round sort: "community" page: 0 pageSize: 0) {
      _id
      code
      community
    }
  }
`;

const PlotPickerContainer: FC<PlotPickerContainerProps> = ({ filter, id, label, rawId, ...form }) => {
  const { loading, data } = useQuery(GET_PLOTS, {
    variables: {
      ...(filter ? filter : {})
    },
    notifyOnNetworkStatusChange: false
  })

  return (
    <SearchSelectInput
      id={id ?? "round"}
      label={label ?? "Plot"}
      placeholder="Select Plot"
      optionsLoading={loading}
      options={(data?.plots ?? [])?.map((plot: any) => ({
        label: {
          title: plot.community as string
        },
        value: rawId ? plot._id : plot
      }))}
      {...form}
    />
  )
}

export default PlotPickerContainer