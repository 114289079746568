import ServiceInfoForm from "./service-info-form";
import CustomerInfoForm from "./customer-info-form";
import IdentityInfoForm from "./identity-info-form";
import PropertyInfoForm from "./property-info-form";
import ServiceInfoSummary from "./service-info-summary";
import PropertyInfoSummary from "./property-info-summary";
import CustomerInfoSummary from "./customer-info-summary";
import IdentityInfoSummary from "./identity-info-summary";

const formSteps = [
  {
    name: "Customer Info",
    description: "Information about the customer making the request.",
    accessor: "customerInfo",
    FormComponent: CustomerInfoForm,
    SummaryComponent: CustomerInfoSummary,
  },
  {
    name: "Identity Info",
    description: "Information about the identity of the customer.",
    accessor: "identityInfo",
    FormComponent: IdentityInfoForm,
    SummaryComponent: IdentityInfoSummary,
  },
  {
    name: "Property Info",
    description: "Information about the facility / premise of the service.",
    accessor: "propertyInfo",
    FormComponent: PropertyInfoForm,
    SummaryComponent: PropertyInfoSummary,
  },
  {
    name: "Service Info",
    description: "Information about the service required by the customer.",
    accessor: "serviceInfo",
    FormComponent: ServiceInfoForm,
    SummaryComponent: ServiceInfoSummary,
  },
];

export default formSteps;
