import { FC } from "react";
import { Navigate, Outlet } from "react-location";
import { useReactiveVar } from "@apollo/client";
import _ from "lodash";
import { currentTokenVar, currentUserVar } from "apollo/cache/auth";
import { Avatar } from "components/core";

const AppLayout: FC = () => {
  const currentToken = useReactiveVar(currentTokenVar);
  const currentUser = useReactiveVar(currentUserVar);


  if (!currentToken) {
    return <Navigate to={"/signin"} replace />;
  }
  return (
    <div className="overflow-hidden flex flex-col" style={{height: "100svh"}}>
      <header className="absolute inset-x-0 top-0 z-50 flex h-16 border-b border-gray-900/10 bg-white">
        <div className="mx-auto flex w-full max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
          <div className="flex flex-1 items-center gap-x-6">
            <img
              className="h-8 w-auto"
              src={require("./../../assets/logo-new.png")}
              alt="ECG"
            />
          </div>
          <div className="flex flex-1 items-center justify-end gap-x-8">
            <a href="/#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your profile</span>
              <Avatar
                src={currentUser?.profileImageUrl}
                alt={
                  [currentUser?.firstName || "", currentUser?.lastName || ""]
                    .join(" ")
                    .trim() || "N A"
                }
              />
            </a>
          </div>
        </div>
      </header>

      <main className="flex-1 flex flex-col overflow-hidden pt-16">
        <Outlet />
      </main>
    </div>
  );
};

export default AppLayout;
