import {
  CustomerTypes,
  Genders,
  IdentityCardTypes,
  Nationalities,
  NeighbourhoodClasses,
  ServiceClasses,
  ServiceRequestCategories,
  ServiceRequestTypes,
  ServiceTypes,
  Titles,
} from "apollo/data";
import lodash from "lodash";
import moment from "moment";
import { validators } from "utils";
import * as Yup from "yup";

export type Title = (typeof Titles)[number];
export type Gender = (typeof Genders)[number];
export type RequestCategory =
  (typeof ServiceRequestCategories)[number]["value"];
export type RequestType = (typeof ServiceRequestTypes)[number]["value"];
export type CustomerType = (typeof CustomerTypes)[number];
export type ServiceType = (typeof ServiceTypes)[number]["value"];
export type ServiceClass = (typeof ServiceClasses)[number]["value"];
export type NeighbourhoodClass = (typeof NeighbourhoodClasses)[number]["value"];
export type IdentityCardType = (typeof IdentityCardTypes)[number];

interface IdentityCardInfo {
  hasGhanaCard: boolean;
  ghanaCardNumber: string;
  ghanaCardIssueDate: string;
  ghanaCardExpiryDate: string;
  ghanaCardFrontImageUrl: string;
  ghanaCardBackImageUrl: string;
  identityCardType: IdentityCardType;
  identityCardNumber: string;
  identityCardIssueDate: string;
  identityCardExpiryDate: string;
  identityCardFrontImageUrl: string;
  identityCardBackImageUrl: string;
}
export interface ICreateRegularizationFormSchema {
  requestInfo: {
    isExistingCustomer: false;
    existingCustomer: any;
    existingServicePoint: any;
    isExistingProperty: false;
    existingProperty: any;
    category: RequestCategory;
    type: RequestType;
    quantity: number;
  };
  customerInfo: {
    customerType: CustomerType;
    representative: {
      title: Title;
      fullName: string;
      nationality: string;
      dateOfBirth: string;
      gender: Gender;
      phoneNumber: string;
      emailAddress: string;
      profileImageUrl: string;
    };
    organization: {
      name: string;
    };
  };
  identityInfo: {
    representative: IdentityCardInfo;
    organization: {
      taxIdentificationNumber: string;
      organizationRegistrationNumber: string;
      organizationRegistrationDate: string;
      organizationRegistrationDocumentUrl: string;
      certificateOfIncorporationDocumentUrl: string;
    };
  };
  propertyInfo: {
    owner: {
      fullName: string;
      phoneNumber: string;
    };
    region: {
      _id: string;
      code: string;
      name: string;
    } | null;
    district: {
      _id: string;
      code: string;
      name: string;
    } | null;
    ghanaPostAddress: string;
    community: string;
    streetName: string;
    houseNumber: string;
    structureNumber: string;
    landmark: string;
    premiseType: {
      _id: string;
      code: string;
      name: string;
    } | null;
    premiseCategory: {
      _id: string;
      code: string;
      name: string;
    } | null;
    activity: {
      _id: string;
      code: string;
      name: string;
    } | null;
    subActivity: {
      _id: string;
      code: string;
      name: string;
    } | null;
    geoLocation: {
      longitude: number;
      latitude: number;
    };
    sitePlanDocumentUrl: string;
  };
  serviceInfo: {
    serviceType: ServiceType;
    serviceClass: ServiceClass;
    meterPhase: number;
    energyCertificateNumber: string;
    energyCertificateDocumentUrl: string;
  };
  billingInfo: {
    neighbourhoodClass: NeighbourhoodClass;
    numberOfRooms: number;
    numberOfMonths: number;
    hasMeter: boolean;
    meterNumber: string;
    meterImageUrl: string;
  };
}

export const RequestInfoSchema = Yup.object().shape({
  category: Yup.string()
    .oneOf(lodash.map(ServiceRequestCategories, "value"))
    .required(),
  type: Yup.string().oneOf(lodash.map(ServiceRequestTypes, "value")).required(),
  quantity: Yup.number().min(1).required(),
  existingCustomer: Yup.object()
    .when("type", {
      is: "NewServiceRequest",
      then: Yup.object()
        .shape({
          title: Yup.string()
            .oneOf([...Titles])
            .notRequired(),
          fullName: Yup.string().notRequired(),
          nationality: Yup.string()
            .oneOf(lodash.map(Nationalities, "nationality"))
            .notRequired(),
          dateOfBirth: Yup.date()
            .max(moment().subtract(18, "years").toDate())
            .notRequired(),
          gender: Yup.string()
            .oneOf([...Genders])
            .notRequired(),
          phoneNumber: Yup.string()
            .matches(/^0(2|5)(0|2|3|4|5|6|7|9)\d{7}$/)
            .notRequired(),
          emailAddress: Yup.string().email().notRequired(),
          profileImageUrl: Yup.string().url().notRequired(),
        })
        .when("isExistingCustomer", {
          is: true,
          then: (schema) => schema.nullable().required(),
          otherwise: (schema) => schema.nullable().notRequired(),
        }),
      otherwise: Yup.object()
        .shape({
          title: Yup.string()
            .oneOf([...Titles])
            .required(),
          fullName: Yup.string().required(),
          nationality: Yup.string()
            .oneOf(lodash.map(Nationalities, "nationality"))
            .required(),
          dateOfBirth: Yup.date()
            .max(moment().subtract(18, "years").toDate())
            .required(),
          gender: Yup.string()
            .oneOf([...Genders])
            .required(),
          phoneNumber: Yup.string()
            .matches(/^0(2|5)(0|2|3|4|5|6|7|9)\d{7}$/)
            .required(),
          emailAddress: Yup.string().email().required(),
          profileImageUrl: Yup.string().url().notRequired(),
        })
        .nullable()
        .required(),
    })
    .nullable(),
});

const PersonalRequiredInfoSchema = Yup.object()
  .nullable()
  .shape({
    title: Yup.string()
      .oneOf([...Titles])
      .required("Kindly select title"),
    fullName: Yup.string().required("Name is required"),
    nationality: Yup.string()
      .oneOf(lodash.map(Nationalities, "nationality"))
      .required("Kindly select nationality"),
    dateOfBirth: Yup.date()
      .max(
        moment().subtract(18, "years").toDate(),
        "Customer must be at least 18 years"
      )
      .required("Date of birth is required"),
    gender: Yup.string()
      .oneOf([...Genders])
      .required("Kindly select gender"),
    phoneNumber: Yup.string()
      .matches(
        /^0(2|5)(0|2|3|4|5|6|7|9)\d{7}$/,
        "Kindly enter a valid phone number"
      )
      .required("Phone number is required"),
    emailAddress: Yup.string()
      .email("Kindly enter a valid email address")
      .notRequired(),
    profileImageUrl: Yup.string().url().notRequired(),
  })
  .required();

const PersonalNotRequiredInfoSchema = Yup.object()
  .nullable()
  .shape({
    title: Yup.string()
      .oneOf([...Titles])
      .notRequired(),
    fullName: Yup.string().notRequired(),
    nationality: Yup.string()
      .oneOf(lodash.map(Nationalities, "nationality"))
      .notRequired(),
    dateOfBirth: Yup.date()
      .max(moment().subtract(18, "years").toDate())
      .notRequired(),
    gender: Yup.string()
      .oneOf([...Genders])
      .notRequired(),
    phoneNumber: Yup.string()
      .matches(
        /^0(2|5)(0|2|3|4|5|6|7|9)\d{7}$/,
        "Kindly enter a valid phone number"
      )
      .notRequired(),
    emailAddress: Yup.string().email().notRequired(),
    profileImageUrl: Yup.string().url().notRequired(),
  })
  .notRequired();

export const CustomerInfoSchema = Yup.object().shape({
  customerType: Yup.string()
    .oneOf([...CustomerTypes])
    .required("Kindly select customer type"),
  representative: Yup.object().nullable().when("customerType", {
    is: "Individual",
    then: PersonalRequiredInfoSchema,
    otherwise: PersonalNotRequiredInfoSchema,
  }),
  organization: Yup.object()
    .nullable()
    .when("customerType", {
      is: "Organization",
      then: Yup.object()
        .shape({
          name: Yup.string().required("Organization name is required"),
        })
        .required(),
      otherwise: Yup.object()
        .nullable()
        .shape({
          name: Yup.string().notRequired(),
        })
        .notRequired(),
    }),
});

export const IdentityCardInfoSchema = Yup.object()
  .nullable()
  .shape({
    hasGhanaCard: Yup.boolean().required(),
    ghanaCardNumber: Yup.string()
      .matches(/^GHA-\d{9}-\d$/, "Kindly enter a valid Ghana Card Number")
      .when("hasGhanaCard", {
        is: true,
        then: Yup.string().required("Ghana Card Number is required"),
        otherwise: Yup.string().notRequired(),
      }),
    ghanaCardIssueDate: Yup.date().when("hasGhanaCard", {
      is: true,
      then: Yup.date().required("Ghana Card issue date is required"),
      otherwise: Yup.date().notRequired(),
    }),
    ghanaCardExpiryDate: Yup.date().when("hasGhanaCard", {
      is: true,
      then: Yup.date().required("Ghana Card expiry date is required"),
      otherwise: Yup.date().notRequired(),
    }),
    ghanaCardFrontImageUrl: Yup.string()
      .url()
      .when("hasGhanaCard", {
        is: true,
        then: Yup.string().required("Kindly attach front image of Ghana Card"),
        otherwise: Yup.string().notRequired(),
      }),
    ghanaCardBackImageUrl: Yup.string()
      .url()
      .when("hasGhanaCard", {
        is: true,
        then: Yup.string().required("Kindly attach rear image of Ghana Card"),
        otherwise: Yup.string().notRequired(),
      }),
    identityCardType: Yup.string()
      .oneOf([...IdentityCardTypes])
      .when("hasGhanaCard", {
        is: false,
        then: Yup.string().required("Kindly select ID Card type"),
        otherwise: Yup.string().notRequired(),
      }),
    identityCardNumber: Yup.string().when("hasGhanaCard", {
      is: false,
      then: Yup.string().required("Kindly enter ID Card number"),
      otherwise: Yup.string().notRequired(),
    }),
    identityCardIssueDate: Yup.date().when("hasGhanaCard", {
      is: false,
      then: Yup.date().required("Kindly enter ID Card issue date"),
      otherwise: Yup.date().notRequired(),
    }),
    identityCardExpiryDate: Yup.date().when("hasGhanaCard", {
      is: false,
      then: Yup.date().required("Kindly enter ID Card expiry date"),
      otherwise: Yup.date().notRequired(),
    }),
    identityCardFrontImageUrl: Yup.string()
      .url()
      .when("hasGhanaCard", {
        is: false,
        then: Yup.string().required("Kindly attach front image of ID Card"),
        otherwise: Yup.string().notRequired(),
      }),
    identityCardBackImageUrl: Yup.string()
      .url()
      .when("hasGhanaCard", {
        is: false,
        then: Yup.string().when("identityCardType", {
          is: "DriversLicense",
          then: (schema) =>
            schema.required("Kindly attach rear image of ID Card"),
          otherwise: (schema) => schema.notRequired(),
        }),
        otherwise: Yup.string().notRequired(),
      }),
  })
  .required();
export const IdentityCardInfoSchemaNotRequired = Yup.object()
  .nullable()
  .shape({
    hasGhanaCard: Yup.boolean().notRequired(),
    ghanaCardNumber: Yup.string().notRequired(),
    ghanaCardIssueDate: Yup.date().notRequired(),
    ghanaCardExpiryDate: Yup.date().notRequired(),
    ghanaCardFrontImageUrl: Yup.string().notRequired(),
    ghanaCardBackImageUrl: Yup.string().notRequired(),
    identityCardType: Yup.string().notRequired(),
    identityCardExpiryDate: Yup.date().notRequired(),
    identityCardFrontImageUrl: Yup.string().notRequired(),
    identityCardBackImageUrl: Yup.string().notRequired(),
  })
  .notRequired();

export const IdentityInfoSchema = (customerType: CustomerType) =>
  Yup.object().shape({
    representative:
      customerType === "Individual"
        ? IdentityCardInfoSchema
        : IdentityCardInfoSchemaNotRequired,
    organization:
      customerType === "Organization"
        ? Yup.object()
            .nullable()
            .shape({
              taxIdentificationNumber: Yup.string()
                .matches(
                  /^(C|P)\d{10}$/,
                  "Enter a valid Tax Identification Number"
                )
                .required("Tax Identification is required"),
              organizationRegistrationNumber: Yup.string()
                .matches(/^CS\d{9}$/, "Enter a valid registration number")
                .required("Registration number is required"),
              organizationRegistrationDate: Yup.date()
                .max(new Date())
                .required("Registration date is required"),
              organizationRegistrationDocumentUrl: Yup.string()
                .url()
                .required(),
              certificateOfIncorporationDocumentUrl: Yup.string()
                .url()
                .required(),
            })
        : Yup.object().nullable().shape({
            taxIdentificationNumber: Yup.string().notRequired(),
            organizationRegistrationNumber: Yup.string().notRequired(),
            organizationRegistrationDate: Yup.date().notRequired(),
            organizationRegistrationDocumentUrl: Yup.string().notRequired(),
            certificateOfIncorporationDocumentUrl: Yup.string().notRequired(),
          }),
  });

export const PropertyInfoSchema = Yup.object().shape({
  owner: Yup.object()
    .shape({
      fullName: Yup.string().required("Kindly enter property owner's name"),
      phoneNumber: Yup.string()
        .matches(
          /^0(2|5)(0|2|3|4|5|6|7|9)\d{7}$/,
          "Kindly enter a valid phone number"
        )
        .required("Phone number is required"),
    })
    .required(),
  region: Yup.object().nullable().required("Kindly select region"),
  district: Yup.object().nullable().required("Kindly select district"),
  ghanaPostAddress: Yup.string()
    .matches(
      validators.GhanaPostRegex,
      "Kindly enter a valid GhanaPost Address"
    )
    .required("GhanaPost Address is required"),
  community: Yup.string().required("Kindly enter community"),
  streetName: Yup.string().required("Kindly enter street name"),
  houseNumber: Yup.string().required("Kindly enter house number"),
  structureNumber: Yup.string().notRequired(),
  landmark: Yup.string().required("Kindly enter closest landmark"),
  premiseType: Yup.object().nullable().required("Kindly select premise type"),
  premiseCategory: Yup.object()
    .nullable()
    .required("Kindly select premise category"),
  activity: Yup.object().nullable().required("Kindly select activity"),
  subActivity: Yup.object().nullable().required("Kindly select sub activity"),
  geoLocation: Yup.object().shape({
    longitude: Yup.number().min(-180).max(180).required(),
    latitude: Yup.number().min(-180).max(180).required(),
  }),
  sitePlanDocumentUrl: Yup.string().url().notRequired(),
});

export const ServiceInfoSchema = Yup.object().shape({
  serviceType: Yup.string().oneOf(lodash.map(ServiceTypes, "value")).required(),
  serviceClass: Yup.string()
    .oneOf(lodash.map(ServiceClasses, "value"))
    .required(),
  meterPhase: Yup.number().required(),
  energyCertificateNumber: Yup.string().notRequired(),
  energyCertificateDocumentUrl: Yup.string().url().notRequired(),
});

export const BillingInfoSchema = Yup.object().shape({
  neighbourhoodClass: Yup.string()
    .oneOf(lodash.map(NeighbourhoodClasses, "value"))
    .required(),
  numberOfRooms: Yup.number().min(1).required(),
  numberOfMonths: Yup.number().min(6).required(),
  hasMeter: Yup.boolean().required(),
  meterNumber: Yup.string().when("hasMeter", {
    is: true,
    then: Yup.string().required("Kindly enter meter number"),
    otherwise: Yup.string().notRequired(),
  }),
  meterImageUrl: Yup.string()
    .url()
    .when("hasMeter", {
      is: true,
      then: Yup.string().required("Kindly attach meter image"),
      otherwise: Yup.string().notRequired(),
    }),
});
