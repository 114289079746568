import { gql, useQuery } from '@apollo/client'
import { FC } from 'react';
import { SearchSelectInput } from 'components/core';

interface TariffClassPickerContainerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any
  setFieldValue: any;
  setFieldTouched: any;
  setFieldError: any;
  rawId?: boolean;
}

const GET_REGIONS = gql`
  query GetTariffClassesSelect {
    tariffClasses: getTariffClasses(sort: "name" page: 0 pageSize: 0) {
      _id
      code
      name
    }
  }
`;

const TariffClassPickerContainer: FC<TariffClassPickerContainerProps> = ({ id, label, rawId, ...form }) => {
  const { loading, data } = useQuery(GET_REGIONS, {
    notifyOnNetworkStatusChange: false
  })

  return (
    <SearchSelectInput
      id={id ?? "tariffClass"}
      label={label ?? "Tariff Class"}
      placeholder="Select Tariff Class"
      optionsLoading={loading}
      options={(data?.tariffClasses ?? [])?.map((tariffClass: any) => ({
        label: {
          title: tariffClass.name as string,
        },
        value: rawId ? tariffClass?._id : tariffClass
      }))}
      {...form}
    />
  )
}

export default TariffClassPickerContainer