import { gql, useLazyQuery } from '@apollo/client'
import { FC } from 'react';
import { AutocompleteInput } from 'components/core';
import lodash from 'lodash';
import { useDebounce } from 'react-use';

interface GooglePlacesSelectContainerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any
  setFieldValue: any;
  handleChange: any;
}

const RESOLVE_GOOGLE_PLACES = gql`
  query ResolveGooglePlaceAddress($address: String!) {
    places: resolveGooglePlaceAddress(address: $address) {
      _id
      name
    }
  }
`;

const GooglePlacesSelectContainer: FC<GooglePlacesSelectContainerProps> = ({ id, label, ...form }) => {
  const [resolvePlaces, { loading, data }] = useLazyQuery(RESOLVE_GOOGLE_PLACES);
  useDebounce(() => {
    const query = lodash.get(form.values, id, "");
    if (query?.length) {
      resolvePlaces({
        variables: {
          address: query
        },
      })
    }
  }, 1000 * 2, [lodash.get(form.values, id)]);

  return (
    <AutocompleteInput
      id={id ?? "place"}
      label={label ?? "Place"}
      placeholder="Select Place"
      optionsLoading={loading}
      options={(data?.places ?? [])?.map((place: any) => ({
        label: {
          title: place.name as string,
        },
        value: place.name
      }))}
      query={lodash.get(form.values, id, "")}
      setQuery={form.handleChange(id)}
      {...form}
      disabled={form.disabled}
    />
  )
}

export default GooglePlacesSelectContainer