import { SelectInput, TextInput, UploadArea } from "components/core";
import { useFormik } from "formik";
import { FC } from "react";
import { classNames, wrapClick } from "utils";
import {
  ICreateShepRegularizationFormSchema,
  IdentityInfoSchema,
} from "./schema";
import { Switch } from "@headlessui/react";
import moment from "moment";

interface IdentityInfoFormProps {
  handleNext: (
    values: ICreateShepRegularizationFormSchema["identityInfo"]
  ) => void;
  handlePrevious: () => void;
  initialValues: ICreateShepRegularizationFormSchema["identityInfo"];
  values: ICreateShepRegularizationFormSchema;
  handleCancel: () => void;
}

const IdentityInfoForm: FC<IdentityInfoFormProps> = ({
  initialValues,
  values,
  handleNext,
  handlePrevious,
  handleCancel,
}) => {
  const form = useFormik<ICreateShepRegularizationFormSchema["identityInfo"]>({
    initialValues,
    validationSchema: IdentityInfoSchema(values.customerInfo.customerType),
    onSubmit: (values) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  return (
    <div className='flex-1 flex flex-col overflow-hidden w-full'>
      <div className='space-y-6 divide-y divide-gray-200 p-4 md:p-6 flex-1 overflow-y-auto'>
        {values.customerInfo.customerType === "Individual" && (
          <>
            <div>
              <span className='text-xs font-light'>
                Identity Type Information
              </span>
              <div className='grid grid-cols-3 gap-4 md:gap-6 mt-2'>
                <div className='col-span-3'>
                  <Switch.Group as='div'>
                    <span className='flex flex-grow flex-col'>
                      <Switch.Label
                        as='span'
                        className='text-sm font-medium text-gray-900'
                        passive
                      >
                        Has Ghana Card
                      </Switch.Label>
                    </span>
                    <div className='flex items-center justify-between mt-1  h-[38px]'>
                      <Switch.Description
                        as='span'
                        className='text-sm text-gray-500'
                      >
                        Do you have a Ghana Card?
                      </Switch.Description>
                      <Switch
                        checked={form.values.representative.hasGhanaCard}
                        onChange={(val: boolean) =>
                          form.setFieldValue("representative.hasGhanaCard", val)
                        }
                        className={classNames(
                          form.values.representative.hasGhanaCard
                            ? "bg-primary-600"
                            : "bg-gray-200",
                          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                        )}
                      >
                        <span
                          aria-hidden='true'
                          className={classNames(
                            form.values.representative.hasGhanaCard
                              ? "translate-x-5"
                              : "translate-x-0",
                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          )}
                        />
                      </Switch>
                    </div>
                  </Switch.Group>
                </div>

                {!form.values.representative.hasGhanaCard && (
                  <div className='col-span-3 md:col-span-1'>
                    <SelectInput
                      id='representative.identityCardType'
                      label='ID Type'
                      options={[
                        { label: "--- Select ID Type ---", value: "" },
                        { label: "Driver's License", value: "DriversLicense" },
                        { label: "Passport", value: "Passport" },
                        {
                          label: "Voters ID",
                          value: "VotersIdentificationCard",
                        },
                      ]}
                      {...form}
                    />
                  </div>
                )}
              </div>
            </div>
            {form.values.representative.hasGhanaCard ? (
              <div className='pt-6'>
                <span className='text-xs font-light'>
                  Customer Identity Information
                </span>
                <div className='grid grid-cols-6 gap-4 md:gap-6 mt-2'>
                  <div className='col-span-6 md:col-span-2'>
                    <TextInput
                      id='representative.ghanaCardNumber'
                      label='Ghana Card Number'
                      type='text'
                      placeholder='eg. GHA-123456789-0'
                      minLength={15}
                      maxLength={15}
                      {...form}
                    />
                  </div>

                  <div className='col-span-3 md:col-span-2'>
                    <TextInput
                      id='representative.ghanaCardIssueDate'
                      label='Ghana Card Issue Date'
                      type='date'
                      max={moment().format("YYYY-MM-DD")}
                      {...form}
                    />
                  </div>

                  <div className='col-span-3 md:col-span-2'>
                    <TextInput
                      id='representative.ghanaCardExpiryDate'
                      label='Ghana Card Expiry Date'
                      type='date'
                      min={moment().format("YYYY-MM-DD")}
                      {...form}
                    />
                  </div>

                  <div className='col-span-6 md:col-span-3'>
                    <UploadArea
                      id='representative.ghanaCardFrontImageUrl'
                      label='Ghana Card Front Image'
                      {...form}
                    />
                  </div>

                  <div className='col-span-6 md:col-span-3'>
                    <UploadArea
                      id='representative.ghanaCardBackImageUrl'
                      label='Ghana Card Back Image'
                      {...form}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className='pt-6'>
                <span className='text-xs font-light'>
                  Customer Identity Information
                </span>
                <div className='grid grid-cols-6 gap-4 md:gap-6 mt-2'>
                  <div className='col-span-6 md:col-span-2'>
                    <TextInput
                      id='representative.identityCardNumber'
                      label={"ID Number"}
                      type='text'
                      placeholder='eg. GH034034034'
                      {...form}
                    />
                  </div>

                  <div className='col-span-3 md:col-span-2'>
                    <TextInput
                      id='representative.identityCardIssueDate'
                      label='ID Issue Date'
                      type='date'
                      max={moment().format("YYYY-MM-DD")}
                      {...form}
                    />
                  </div>

                  {form.values.representative?.identityCardType !==
                    "VotersIdentificationCard" && (
                    <div className='col-span-3 md:col-span-2'>
                      <TextInput
                        id='representative.identityCardExpiryDate'
                        label='ID Expiry Date'
                        type='date'
                        min={moment().format("YYYY-MM-DD")}
                        {...form}
                      />
                    </div>
                  )}

                  <div className='col-span-6 md:col-span-3 col-start-1'>
                    <UploadArea
                      id='representative.identityCardFrontImageUrl'
                      label='ID Front Image'
                      {...form}
                    />
                  </div>

                  {form.values.representative?.identityCardType ===
                    "DriversLicense" && (
                    <div className='col-span-6 md:col-span-3'>
                      <UploadArea
                        id='representative.identityCardBackImageUrl'
                        label='ID Back Image'
                        {...form}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
        {values.customerInfo.customerType === "Organization" && (
          <>
            <div className=''>
              <span className='text-xs font-light'>
                Organization Identity Information
              </span>
              <div className='grid grid-cols-6 gap-4 md:gap-6 mt-2'>
                <div className='col-span-6 md:col-span-2'>
                  <TextInput
                    id='organization.taxIdentificationNumber'
                    label='Tax Identification Number'
                    type='text'
                    placeholder='eg. C0012345678'
                    maxLength={11}
                    {...form}
                  />
                </div>

                <div className='col-span-6 md:col-span-2'>
                  <TextInput
                    id='organization.organizationRegistrationNumber'
                    label='Organization Registration Number'
                    type='text'
                    placeholder='eg. CS012345678'
                    maxLength={11}
                    {...form}
                  />
                </div>

                <div className='col-span-6 md:col-span-2'>
                  <TextInput
                    id='organization.organizationRegistrationDate'
                    label='Organization Registration Date'
                    type='date'
                    max={moment().format("YYYY-MM-DD")}
                    {...form}
                  />
                </div>

                <div className='col-span-6 md:col-span-2'>
                  <UploadArea
                    id='organization.certificateOfIncorporationDocumentUrl'
                    label='Certificate of Incorporation'
                    accept={{
                      "image/*": [".png", ".jpeg", ".jpg"],
                      "application/pdf": [".pdf"],
                    }}
                    {...form}
                  />
                </div>

                <div className='col-span-6 md:col-span-2'>
                  <UploadArea
                    id='organization.organizationRegistrationDocumentUrl'
                    label='Business Regsitration Document'
                    accept={{
                      "image/*": [".png", ".jpeg", ".jpg"],
                      "application/pdf": [".pdf"],
                    }}
                    {...form}
                  />
                </div>
              </div>
            </div>
            <div className='pt-6'>
              <span className='text-xs font-light'>
                Identity Type Information
              </span>
              <div className='grid grid-cols-3 gap-4 md:gap-6 mt-2'>
                <div className='col-span-3'>
                  <Switch.Group as='div'>
                    <span className='flex flex-grow flex-col'>
                      <Switch.Label
                        as='span'
                        className='text-sm font-medium text-gray-900'
                        passive
                      >
                        Has Ghana Card
                      </Switch.Label>
                    </span>
                    <div className='flex items-center justify-between mt-1  h-[38px]'>
                      <Switch.Description
                        as='span'
                        className='text-sm text-gray-500'
                      >
                        Do you have a Ghana Card?
                      </Switch.Description>
                      <Switch
                        checked={form.values.representative.hasGhanaCard}
                        onChange={(val: boolean) =>
                          form.setFieldValue("representative.hasGhanaCard", val)
                        }
                        className={classNames(
                          form.values.representative.hasGhanaCard
                            ? "bg-primary-600"
                            : "bg-gray-200",
                          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                        )}
                      >
                        <span
                          aria-hidden='true'
                          className={classNames(
                            form.values.representative.hasGhanaCard
                              ? "translate-x-5"
                              : "translate-x-0",
                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          )}
                        />
                      </Switch>
                    </div>
                  </Switch.Group>
                </div>

                {!form.values.representative.hasGhanaCard && (
                  <div className='col-span-3 md:col-span-1'>
                    <SelectInput
                      id='representative.identityCardType'
                      label='ID Type'
                      options={[
                        { label: "--- Select ID Type ---", value: "" },
                        { label: "Driver's License", value: "DriversLicense" },
                        { label: "Passport", value: "Passport" },
                        {
                          label: "Voters ID",
                          value: "VotersIdentificationCard",
                        },
                      ]}
                      {...form}
                    />
                  </div>
                )}
              </div>
            </div>
            {form.values.representative.hasGhanaCard ? (
              <div className='pt-6'>
                <span className='text-xs font-light'>
                  Representative Identity Information
                </span>
                <div className='grid grid-cols-6 gap-4 md:gap-6 mt-2'>
                  <div className='col-span-6 md:col-span-2'>
                    <TextInput
                      id='representative.ghanaCardNumber'
                      label='Ghana Card Number'
                      type='text'
                      placeholder='eg. GHA-123456789-0'
                      minLength={15}
                      maxLength={15}
                      {...form}
                    />
                  </div>

                  <div className='col-span-3 md:col-span-2'>
                    <TextInput
                      id='representative.ghanaCardIssueDate'
                      label='Ghana Card Issue Date'
                      type='date'
                      {...form}
                    />
                  </div>

                  <div className='col-span-3 md:col-span-2'>
                    <TextInput
                      id='representative.ghanaCardExpiryDate'
                      label='Ghana Card Expiry Date'
                      type='date'
                      {...form}
                    />
                  </div>

                  <div className='col-span-6 md:col-span-3'>
                    <UploadArea
                      id='representative.ghanaCardFrontImageUrl'
                      label='Ghana Card Front Image'
                      {...form}
                    />
                  </div>

                  <div className='col-span-6 md:col-span-3'>
                    <UploadArea
                      id='representative.ghanaCardBackImageUrl'
                      label='Ghana Card Back Image'
                      {...form}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className='pt-6'>
                <span className='text-xs font-light'>
                  Customer Identity Information
                </span>
                <div className='grid grid-cols-6 gap-6 mt-2'>
                  <div className='col-span-6 md:col-span-2'>
                    <TextInput
                      id='representative.identityCardNumber'
                      label={"ID Number"}
                      type='text'
                      placeholder='eg. GH034034034'
                      {...form}
                    />
                  </div>

                  <div className='col-span-6 md:col-span-2'>
                    <TextInput
                      id='representative.identityCardIssueDate'
                      label='ID Issue Date'
                      type='date'
                      {...form}
                    />
                  </div>

                  {form.values.representative?.identityCardType !==
                    "VotersIdentificationCard" && (
                    <div className='col-span-2'>
                      <TextInput
                        id='representative.identityCardExpiryDate'
                        label='ID Expiry Date'
                        type='date'
                        {...form}
                      />
                    </div>
                  )}

                  <div className='col-span-3 col-start-1'>
                    <UploadArea
                      id='representative.identityCardFrontImageUrl'
                      label='ID Front Image'
                      {...form}
                    />
                  </div>

                  {form.values.representative?.identityCardType ===
                    "DriversLicense" && (
                    <div className='col-span-3'>
                      <UploadArea
                        id='representative.identityCardBackImageUrl'
                        label='ID Back Image'
                        {...form}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div className='bg-gray-50 px-4 py-3 sm:py-4 sm:px-6 flex flex-row-reverse border-t border-gray-200'>
        <button
          type='button'
          onClick={wrapClick(form.handleSubmit)}
          className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 ml-3 sm:w-auto sm:text-sm'
        >
          Next
        </button>
        <button
          type='button'
          className='w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type='button'
          className='hidden  w-full md:inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default IdentityInfoForm;
