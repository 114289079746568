import { gql, useQuery } from '@apollo/client'
import { FC } from 'react';
import { SearchSelectInput } from 'components/core';

interface MeterBrandPickerContainerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any
  setFieldValue: any;
  setFieldTouched?: any;
  rawId?: boolean;
}

const GET_METER_BRANDS = gql`
  query GetMeterBrandsSelect {
    meterBrands: getMeterBrands(sort: "name" page: 0 pageSize: 0) {
      _id
      code
      name
    }
  }
`;

const MeterBrandPickerContainer: FC<MeterBrandPickerContainerProps> = ({ id, label, rawId, ...form }) => {
  const { loading, data } = useQuery(GET_METER_BRANDS, {
    notifyOnNetworkStatusChange: false,
  })

  return (
    <SearchSelectInput
      id={id ?? "brand"}
      label={label ?? "Meter Brand"}
      placeholder="Select Meter Brand"
      optionsLoading={loading}
      options={(data?.meterBrands ?? [])?.map((meterBrand: any) => ({
        label: {
          title: meterBrand?.name as string,
        },
        value: rawId ? meterBrand?._id : meterBrand
      }))}
      {...form}
      disabled={form.disabled}
    />
  )
}

export default MeterBrandPickerContainer