import { TextInput, UploadArea } from "components/core";
import { useFormik } from "formik";
import { FC } from "react";
import { wrapClick, classNames } from "utils";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { ICreateShepRegularizationFormSchema, ServiceInfoSchema } from "./schema";
import { ServiceClasses, ServiceTypes, MeterPhases } from "apollo/data";
import { MeterBrandPicker, MeterModelPicker } from "containers";
import moment from "moment";

interface ServiceInfoFormProps {
  handleNext: (values: ICreateShepRegularizationFormSchema["serviceInfo"]) => void;
  handlePrevious: () => void;
  initialValues: ICreateShepRegularizationFormSchema["serviceInfo"];
  values: ICreateShepRegularizationFormSchema;
  handleCancel: () => void;
}

const ServiceInfoForm: FC<ServiceInfoFormProps> = ({
  initialValues,
  handleNext,
  handlePrevious,
  handleCancel,
}) => {
  const form = useFormik<ICreateShepRegularizationFormSchema["serviceInfo"]>({
    initialValues,
    validationSchema: ServiceInfoSchema,
    onSubmit: (values) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  return (
    <form
      onSubmit={form.handleSubmit}
      className="flex-1 flex flex-col overflow-hidden"
    >
      <div className="space-y-6 divide-y divide-gray-200 p-4 md:p-6 flex-1 overflow-y-auto">
        <div>
          <span className="text-xs font-light">Service Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="col-span-3">
              <RadioGroup
                value={form.values.serviceClass}
                onChange={form.handleChange("serviceClass")}
              >
                <RadioGroup.Label className="text-sm font-medium text-gray-700">
                  Service Class
                </RadioGroup.Label>
                <div className="mt-1 grid grid-cols-2 gap-x-4 gap-y-4 sm:gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                  {ServiceClasses.map((serviceClass) => (
                    <RadioGroup.Option
                      key={serviceClass.value}
                      value={serviceClass.value}
                      className={({ checked, active }) =>
                        classNames(
                          checked ? "border-transparent" : "border-gray-300",
                          active
                            ? "border-primary-500 ring-2 ring-primary-500"
                            : "",
                          "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
                        )
                      }
                    >
                      {({ checked, active }) => (
                        <>
                          <span className="flex flex-1">
                            <span className="flex flex-col">
                              <RadioGroup.Label
                                as="span"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {serviceClass.title}
                              </RadioGroup.Label>
                              <RadioGroup.Description
                                as="span"
                                className="mt-1 flex items-center text-sm text-gray-500"
                              >
                                {serviceClass.description}
                              </RadioGroup.Description>
                            </span>
                          </span>
                          <CheckCircleIcon
                            className={classNames(
                              !checked ? "invisible" : "",
                              "h-5 w-5 text-primary-600"
                            )}
                            aria-hidden="true"
                          />
                          <span
                            className={classNames(
                              active ? "border" : "border-2",
                              checked
                                ? "border-primary-500"
                                : "border-transparent",
                              "pointer-events-none absolute -inset-px rounded-lg"
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>
          </div>
        </div>
        <div className="pt-6">
          <span className="text-xs font-light">
            Meter Information
          </span>
          <div className="grid grid-cols-6 gap-4 md:gap-6 mt-2">
            <div className="col-span-6 md:col-span-2">
              <MeterBrandPicker
                id="meterBrand"
                label="Meter Brand"
                placeholder="eg. Huawei"
                required={true}
                {...form}
              />
            </div>
            <div className="col-span-6 md:col-span-2">
              <MeterModelPicker
                id="meterModel"
                label="Meter Model"
                placeholder="eg. Wasion"
                required={true}
                {...form}
              />
            </div>
            <div className="col-span-6 md:col-span-2">
              <TextInput
                id="meterNumber"
                label="Meter Number"
                type="text"
                placeholder="eg. P1412324242"
                required={true}
                {...form}
              />
            </div>
            <div className="col-span-6 md:col-span-2 md:col-start-1">
              <TextInput
                id="installationDate"
                label="Installation Date"
                type="date"
                required={true}
                max={moment().format("YYYY-MM-DD")}
                {...form}
              />
            </div>
            <div className="col-span-6 md:col-span-2">
              <TextInput
                id="initialReadingValue"
                label="Current Reading Value"
                type="number"
                placeholder="eg. 23"
                required={true}
                min={0}
                step={1}
                {...form}
                postText="KWh"
              />
            </div>
            <div className="col-span-6 md:col-span-2 md:col-start-1">
              <TextInput
                id="initialReadingDate"
                label="Current Reading Date"
                type="date"
                required={true}
                max={moment().format("YYYY-MM-DD")}
                {...form}
              />
            </div>
            <div className="col-span-6 md:col-span-2">
              <TextInput
                id="contractedDemand"
                label="Contracted Demand"
                type="number"
                placeholder="eg. 0.5"
                required={true}
                min={0}
                step={1}
                {...form}
                postText="KVA"
              />
            </div>
            <div className="col-span-6 md:col-span-4 md:row-start-2 md:col-start-3 md:row-span-5">
              <UploadArea
                id="meterImageUrl"
                label="Meter Image"
                accept={{
                  "image/*": [".png", ".jpeg", ".jpg"],
                }}
                {...form}
              />
            </div>
          </div>
        </div>
        <div className="pt-6">
          <span className="text-xs font-light">
            Energy Certification Information
          </span>
          <div className="grid grid-cols-6 gap-6 mt-2">
            <div className="col-span-6 md:col-span-2">
              <TextInput
                id="energyCertificateNumber"
                label="Energy Certification Number"
                type="text"
                placeholder="eg. GH034034034"
                {...form}
              />
            </div>
            <div className="col-span-6 md:col-span-4">
              <UploadArea
                id="energyCertificateDocumentUrl"
                label="Energy Commission Certificate"
                accept={{
                  "image/*": [".png", ".jpeg", ".jpg"],
                  "application/pdf": [".pdf"],
                }}
                {...form}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 px-4 py-3 sm:py-4 sm:px-6 flex flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(form.handleSubmit)}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="hidden  w-full md:inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default ServiceInfoForm;
