import { FC } from "react";
import { ICreateShepRegularizationFormSchema } from "./schema";
import _ from "lodash";
import { wrapImage } from "utils";

const ContractInfoSummary: FC<{
  data: ICreateShepRegularizationFormSchema["serviceInfo"];
}> = ({ data }) => (
  <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
    <div className="">
      <span className="text-xs font-light">Service Information</span>
      <div className="grid grid-cols-6 gap-4 md:gap-6 mt-2">
        <div className="col-span-3 md:col-span-2">
          <span className="block text-sm font-light text-gray-700">
            Service Class
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.serviceClass ? _.startCase(data.serviceClass) : "N/A"}
          </div>
        </div>
      </div>
    </div>
    <div className="pt-6">
      <span className="text-xs font-light">Meter Information</span>
      <div className="grid grid-cols-3 gap-4 md:gap-6 mt-2">
        <div className="col-span-3 md:col-span-1">
          <span className="block text-sm font-light text-gray-700">
            Meter Brand
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.meterBrand?.name || "N/A"}
          </div>
        </div>
        <div className="col-span-3 md:col-span-1">
          <span className="block text-sm font-light text-gray-700">
            Meter Model
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.meterModel?.name || "N/A"}
          </div>
        </div>
        <div className="col-span-3 md:col-span-1">
          <span className="block text-sm font-light text-gray-700">
            Meter Number
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.meterNumber || "N/A"}
          </div>
        </div>
        <div className="col-span-3 md:col-span-1">
          <span className="block text-sm font-light text-gray-700">
            Current Reading Date
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.initialReadingDate || "N/A"}
          </div>
        </div>
        <div className="col-span-3 md:col-span-1">
          <span className="block text-sm font-light text-gray-700">
            Current Reading Value
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.initialReadingValue || "N/A"} KWh
          </div>
        </div>
        <div className="col-span-3 md:col-span-1">
          <span className="block text-sm font-light text-gray-700">
            Contracted Demand
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.initialReadingValue || "N/A"} KWh
          </div>
        </div>
        <div className="col-span-3 md:col-span-2">
          <span className="block text-sm font-light text-gray-700">
            Meter Image
          </span>
          <div className="mt-1 block sm:text-sm">
            {wrapImage(
              <img
                src={data?.meterImageUrl}
                alt={data?.meterNumber}
                className="w-full  h-64 object-cover object-top text-xs"
              />
            )}
          </div>
        </div>
      </div>
    </div>
    <div className="pt-6">
      <span className="text-xs font-light">
        Energy Certification Information
      </span>
      <div className="grid grid-cols-3 gap-4 md:gap-6 mt-2">
        <div className="col-span-3 md:col-span-1">
          <span className="block text-sm font-light text-gray-700">
            Energy Certificate Number
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.energyCertificateNumber || "N/A"}
          </div>
        </div>
        <div className="col-span-3 md:col-span-2">
          <span className="block text-sm font-light text-gray-700">
            Energy Certificate Document
          </span>
          {data?.energyCertificateNumber ? (
            <div className="mt-1 block sm:text-sm">
              {wrapImage(
                <img
                  src={data?.energyCertificateDocumentUrl}
                  alt={data?.energyCertificateNumber}
                  className="w-full  h-64 object-cover object-top text-xs"
                />
              )}
            </div>
          ) : (
            <div className="border-2 border-dashed border-gray-300 text-gray-600 flex items-center justify-center h-64 rounded-md mt-2">
              <span>Not Provided</span>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default ContractInfoSummary;
