import { FC } from "react";
import { ICreateShepRegularizationFormSchema } from "./schema";
import { ICurrentConfig } from "apollo/cache/config";
import moment from "moment";
import { Avatar } from "components/core";

const CustomerInfoSummary: FC<{
  data: ICreateShepRegularizationFormSchema["customerInfo"];
  config: ICurrentConfig;
}> = ({ data, config: { dateFormat } }) => (
  <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
    <div className='pt-6'>
      <span className='text-xs font-light'>Customer Information</span>
      <div className='grid grid-cols-3 gap-4 md:gap-6 mt-2'>
        <div className='col-span-2'>
          <span className='block text-sm font-light text-gray-700'>
            Customer Type
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.customerType || "N/A"}
          </div>
        </div>
      </div>
    </div>
    {data?.customerType === "Individual" && (
      <>
        <div className='pt-6'>
          <span className='text-xs font-light'>Customer Information</span>
          <div className='grid grid-cols-6 gap-4 md:gap-6 mt-2'>
            <div className='col-span-2'>
              <span className='block text-sm font-light text-gray-700'>
                Photo
              </span>
              <Avatar
                alt={data?.representative.fullName || "N A"}
                src={data?.representative.profileImageUrl || ""}
                size='lg'
              />
            </div>
            <div className='col-start-1 col-span-3 md:col-span-2'>
              <span className='block text-sm font-light text-gray-700'>
                Title
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {data?.representative?.title || "N/A"}
              </div>
            </div>
            <div className='col-span-3 md:col-span-2'>
              <span className='block text-sm font-light text-gray-700'>
                Gender
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {data?.representative?.gender || "N/A"}
              </div>
            </div>
            <div className='col-span-6 md:col-span-4'>
              <span className='block text-sm font-light text-gray-700'>
                Full Name
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {data?.representative?.fullName || "N/A"}
              </div>
            </div>
            <div className='col-span-3 md:col-span-2'>
              <span className='block text-sm font-light text-gray-700'>
                Nationality
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {data?.representative?.nationality || "N/A"}
              </div>
            </div>
            <div className='col-span-3 md:col-span-2'>
              <span className='block text-sm font-light text-gray-700'>
                Date of Birth
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {moment(data?.representative?.dateOfBirth).format(dateFormat)}
              </div>
            </div>
          </div>
        </div>
        <div className='pt-6'>
          <span className='text-xs font-light'>Contact Information</span>
          <div className='grid grid-cols-6 gap-4 md:gap-6 mt-2'>
            <div className='col-span-6 md:col-span-2'>
              <span className='block text-sm font-light text-gray-700'>
                Phone Number
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {data?.representative?.phoneNumber || "N/A"}
              </div>
            </div>
            <div className='col-span-6 md:col-span-4'>
              <span className='block text-sm font-light text-gray-700'>
                Email Address
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {data?.representative?.emailAddress || "N/A"}
              </div>
            </div>
          </div>
        </div>
      </>
    )}
    {data?.customerType === "Organization" && (
      <>
        <div className='pt-6'>
          <span className='text-xs font-light'>Organization Information</span>
          <div className='grid grid-cols-3 gap-4 md:gap-6 mt-2'>
            <div className='col-span-3 md:col-span-2'>
              <span className='block text-sm font-light text-gray-700'>
                Name
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {data?.organization?.name || "N/A"}
              </div>
            </div>
          </div>
        </div>
        <div className='pt-6'>
          <span className='text-xs font-light'>Representative Information</span>
          <div className='grid grid-cols-3 gap-4 md:gap-6 mt-2'>
            <div className='col-span-3 md:col-span-2'>
              <span className='block text-sm font-light text-gray-700'>
                Title
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {data?.representative?.title || "N/A"}
              </div>
            </div>
            <div className='col-span-3 md:col-span-2'>
              <span className='block text-sm font-light text-gray-700'>
                Gender
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {data?.representative?.gender || "N/A"}
              </div>
            </div>
            <div className='col-span-6 md:col-span-2'>
              <span className='block text-sm font-light text-gray-700'>
                Full Name
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {data?.representative?.fullName || "N/A"}
              </div>
            </div>
            <div className='col-span-3 md:col-span-2'>
              <span className='block text-sm font-light text-gray-700'>
                Nationality
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {data?.representative?.nationality || "N/A"}
              </div>
            </div>
            <div className='col-span-3 md:col-span-2'>
              <span className='block text-sm font-light text-gray-700'>
                Date of Birth
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {moment(data?.representative?.dateOfBirth).format(dateFormat)}
              </div>
            </div>
          </div>
        </div>
        <div className='pt-6'>
          <span className='text-xs font-light'>
            Representative's Contact Information
          </span>
          <div className='grid grid-cols-3 gap-4 md:gap-6 mt-2'>
            <div className='col-span-3 md:col-span-1'>
              <span className='block text-sm font-light text-gray-700'>
                Phone Number
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {data?.representative?.phoneNumber || "N/A"}
              </div>
            </div>
            <div className='col-span-3 md:col-span-2'>
              <span className='block text-sm font-light text-gray-700'>
                Email Address
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {data?.representative?.emailAddress || "N/A"}
              </div>
            </div>
          </div>
        </div>
      </>
    )}
  </div>
);

export default CustomerInfoSummary;
