import { CheckIcon } from "@heroicons/react/24/solid"
import _ from "lodash";
import { FC } from "react"

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

interface StepWizardProps {
  steps: any[];
  step: number;
  setStep: (step: number) => void;
  lastStep: number;
}

const StepWizard: FC<StepWizardProps> = ({ steps, step: currentStep, setStep, lastStep }) => {

  return (
    <nav className="flex p-4 justify-center bg-gray-50">
      <ol className="flex-1 flex space-x-2 md:space-x-8">
        {steps.map((step, stepIdx) => (
          <li key={step.href} className="flex-1 flex">
            {step.href === currentStep ? (
              <button
                onClick={() => setStep(step.href)}
                disabled={step.href > lastStep}
                className={classNames(
                  step.href > lastStep ? "cursor-not-allowed" : "cursor-pointer",
                  "flex-1 flex flex-col md:border-primary-600 md:border-t-4 md:pt-4",
                  "h-1 sm:h-auto bg-primary-600 md:bg-inherit",
                )}
                aria-current="step"
              >
                <span className="text-sm hidden md:block font-medium text-primary-600">{step.id}</span>
                <span className="text-sm hidden md:block font-medium">{step.name}</span>
              </button>
            ) : step.href < lastStep ? (
              <button
                onClick={() => setStep(step.href)}
                disabled={step.href > lastStep}
                className={classNames(
                  step.href > lastStep ? "cursor-not-allowed" : "cursor-pointer",
                  "flex-1 group flex flex-col md:border-primary-600 md:hover:border-primary-800 md:border-t-4 md:pt-4",
                  "h-1 sm:h-auto bg-primary-600 hover:bg-primary-800 md:bg-inherit md:hover:bg-inherit",
                )}
              >
                <span className="text-sm hidden md:block font-medium text-primary-600 group-hover:text-primary-800">{step.id}</span>
                <span className="text-sm hidden md:block font-medium">{step.name}</span>
              </button>
            ) : (
              <button
                onClick={() => setStep(step.href)}
                disabled={step.href > lastStep}
                className={classNames(
                  step.href > lastStep ? "cursor-not-allowed" : "cursor-pointer",
                  "flex-1 group flex flex-col md:border-gray-200 md:hover:border-gray-300 md:border-t-4 md:pt-4",
                  "h-1 sm:h-auto bg-gray-200 hover:bg-gray-300 md:bg-inherit md:hover:bg-inherit"
                )}
              >
                <span className="text-sm hidden md:block font-medium text-gray-500 group-hover:text-gray-700">{step.id}</span>
                <span className="text-sm hidden md:block font-medium">{step.name}</span>
              </button>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default StepWizard
