import { gql, useMutation, useReactiveVar } from "@apollo/client";
import { CreateShepRegularizationForm } from "components";
import { FC } from "react";
import { useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { ICreateShepRegularizationFormSchema } from "components/forms/create-shep-regularization/schema";
import _ from "lodash";
import { currentUserVar } from "apollo/cache/auth";

const CREATE_SERVICE_REQUEST = gql`
  mutation CreateShepRegularization(
    $customer: ServiceRequestCustomerInput!
    $property: ServiceRequestPropertyInput!
    $service: ShepRegularizationServiceInput!
    $region: ID
    $district: ID
  ) {
    createServiceRequest: createShepRegularization(
      customer: $customer
      property: $property
      service: $service
      region: $region
      district: $district
    ) {
      _id
    }
  }
`;

const CreateServiceRequestPage: FC = () => {
  const currentUser = useReactiveVar(currentUserVar);
  const navigate = useNavigate<LocationGenerics>();
  const [createServiceRequest, { loading }] = useMutation(
    CREATE_SERVICE_REQUEST
  );

  const form = useFormik<ICreateShepRegularizationFormSchema>({
    initialValues: {
      customerInfo: {
        customerType: "Individual",

        organization: {
          name: "",
        },
        representative: {
          title: "Mr",
          fullName: "",
          nationality: "Ghanaian",
          dateOfBirth: "",
          gender: "Male",
          phoneNumber: "",
          emailAddress: "",
          profileImageUrl: "",
        },
      },
      identityInfo: {
        organization: {
          taxIdentificationNumber: "",
          organizationRegistrationNumber: "",
          organizationRegistrationDate: "",
          organizationRegistrationDocumentUrl: "",
          certificateOfIncorporationDocumentUrl: "",
        },
        representative: {
          hasGhanaCard: true,
          ghanaCardNumber: "",
          ghanaCardIssueDate: "",
          ghanaCardExpiryDate: "",
          ghanaCardFrontImageUrl: "",
          ghanaCardBackImageUrl: "",
          identityCardType: "DriversLicense",
          identityCardNumber: "",
          identityCardIssueDate: "",
          identityCardExpiryDate: "",
          identityCardFrontImageUrl: "",
          identityCardBackImageUrl: "",
        },
      },
      propertyInfo: {
        owner: {
          fullName: "",
          phoneNumber: "",
        },
        region: null,
        district: null,
        ghanaPostAddress: "",
        community: "",
        streetName: "",
        houseNumber: "",
        structureNumber: "",
        landmark: "",
        geoCode: "",
        premiseType: null,
        premiseCategory: null,
        activity: null,
        subActivity: null,
        geoLocation: {
          longitude: -0.234361,
          latitude: 5.667032,
        },
        sitePlanDocumentUrl: "",
      },
      serviceInfo: {
        serviceClass: "Residential",
        meterNumber: "",
        meterBrand: null,
        meterModel: null,
        initialReadingValue: 0,
        installationDate: "",
        initialReadingDate: "",
        contractedDemand: 0,
        meterImageUrl: "",
        energyCertificateNumber: "",
        energyCertificateDocumentUrl: "",
      },
    },
    onSubmit: async (values) => {
      const customerData = _.chain(values.customerInfo)
        .merge(values.identityInfo)
        .thru((val) => {
          return _.pick(val, [
            "customerType",
            "category",
            ...(val.customerType === "Individual"
              ? ["representative"]
              : ["organization", "representative"]),
          ]);
        })
        .tap((val) => {
          (val.customerType === "Individual"
            ? val.representative?.hasGhanaCard
              ? [
                  "representative.identityCardType",
                  "representative.identityCardNumber",
                  "representative.identityCardIssueDate",
                  "representative.identityCardExpiryDate",
                  "representative.identityCardFrontImageUrl",
                  "representative.identityCardBackImageUrl",
                ]
              : [
                  "representative.ghanaCardNumber",
                  "representative.ghanaCardIssueDate",
                  "representative.ghanaCardExpiryDate",
                  "representative.ghanaCardFrontImageUrl",
                  "representative.ghanaCardBackImageUrl",
                ]
            : val.representative?.hasGhanaCard
            ? [
                "representative.identityCardType",
                "representative.identityCardNumber",
                "representative.identityCardIssueDate",
                "representative.identityCardExpiryDate",
                "representative.identityCardFrontImageUrl",
                "representative.identityCardBackImageUrl",
              ]
            : [
                "representative.ghanaCardNumber",
                "representative.ghanaCardIssueDate",
                "representative.ghanaCardExpiryDate",
                "representative.ghanaCardFrontImageUrl",
                "representative.ghanaCardBackImageUrl",
              ]
          ).forEach((field) => _.unset(val, field));
        })
        .value();
      await createServiceRequest({
        variables: {
          customer: customerData,
          property: {
            ...values.propertyInfo,
            region: values.propertyInfo.region?._id || currentUser.region?._id,
            district:
              values.propertyInfo.district?._id || currentUser.district?._id,
            premiseType: values.propertyInfo.premiseType?._id,
            premiseCategory: values.propertyInfo.premiseCategory?._id,
            activity: values.propertyInfo.activity?._id,
            subActivity: values.propertyInfo.subActivity?._id,
          },
          service: {
            ...values.serviceInfo,
            meterModel: values.serviceInfo.meterModel?._id,
            meterBrand: values.serviceInfo.meterBrand?._id,
          },
          region: values.propertyInfo.region?._id || currentUser.region?._id,
          district:
            values.propertyInfo.district?._id || currentUser.district?._id,
        },
      }).then(({ data }) => {
        if (data.createServiceRequest._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "SHEP Captured Successfully",
            })
          );
          form.resetForm();
          navigate({
            to: "/shep-regularization",
            search: {},
            replace: true,
          });
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not capture SHEP",
            })
          );
        }
      });
    },
    onReset: () => {
      navigate({
        to: "/shep-regularization",
        replace: true,
      });
    },
  });

  return (
    <main className='isolate flex-1 flex flex-col overflow-hidden bg-white px-0 sm:px-6 lg:px-8'>
      <header className='py-4'>
        <div className='mx-auto flex max-w-7xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8'>
          <h1 className='text-base font-semibold leading-7 text-gray-900'>
            New SHEP Capture
          </h1>
        </div>
      </header>
      <div className='flex flex-1 overflow-hidden'>
        <CreateShepRegularizationForm form={form} isSubmitLoading={loading} />
      </div>
    </main>
  );
};

export default CreateServiceRequestPage;
