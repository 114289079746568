import { FC } from "react"
import { ICreateRegularizationFormSchema } from "./schema"
import { ICurrentConfig } from "apollo/cache/config";
import moment from "moment";
import lodash from "lodash";

const RequestInfoSummary: FC<{ data: ICreateRegularizationFormSchema["requestInfo"]; config: ICurrentConfig }> = ({ data, config: { dateFormat } }) => (
  <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
    <div className=''>
      <span className="text-xs font-light">Request Information</span>
      <div className='grid grid-cols-3 gap-6 mt-2'>
        <div className="col-span-1">
          <span className="block text-sm font-light text-gray-700">
            Request Category
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.category || "N/A"}
          </div>
        </div>
        <div className="col-span-1">
          <span className="block text-sm font-light text-gray-700">
            Customer Type
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {lodash.startCase(data?.type) || "N/A"}
          </div>
        </div>
        {data?.type === "NewServiceRequest" && (
          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Is Existing Customer?
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.isExistingCustomer ? "Yes" : "No"}
            </div>
          </div>
        )}
        {data?.type !== "AdditionalLoadRequest" && (
          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Number of Meters
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.quantity || "N/A"} meters
            </div>
          </div>
        )}
      </div>
    </div>
    {data?.existingCustomer && (
      <div className='pt-6'>
        <span className="text-xs font-light">Existing Customer Information</span>
        <div className="mt-2 border border-gray-300 border-dashed bg-gray-100 divide-y divide-dashed divide-gray-300 rounded-md">
          <div className="grid grid-cols-4 gap-4 p-4">
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Type
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.existingCustomer?.title || "N/A"}
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Title
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.existingCustomer?.title || "N/A"}
              </div>
            </div>
            <div className="col-span-2">
              <span className="block text-sm font-light text-gray-700">
                Full Name
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.existingCustomer?.fullName || "N/A"}
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Nationality
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.existingCustomer?.nationality || "N/A"}
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Date of Birth
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {moment(data?.existingCustomer?.dateOfBirth).format(dateFormat)}
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Gender
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.existingCustomer?.gender || "N/A"}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4 p-4">
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Account Number
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.existingCustomer?.response?.account?.code || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Customer Number
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.existingCustomer?.response?.customer?.code || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Property Number
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.existingCustomer?.response?.property?.code || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Service Point Number
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.existingCustomer?.response?.servicePoint?.code || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Geographical Code
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.existingCustomer?.response?.servicePoint?.geoCode || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Meter Number
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.existingCustomer?.response?.meter?.code || "N/A"}
              </div>
            </div>
            <div className="col-span-2">
              <span className="block text-sm font-light text-gray-700">
                Property Address
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.existingCustomer?.response?.meter?.code || "N/A"}
              </div>
            </div>
          </div>
        </div>
      </div>
    )}

  </div>
)

export default RequestInfoSummary