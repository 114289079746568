import { Route } from "react-location";
import { DashboardPage, ForgotPasswordPage, SigninPage, AuthPage, RegularizationPage, CreateRegularizationPage, ShepRegularizationPage, CreateShepRegularizationPage } from "pages";
import { ChartBarSquareIcon, UsersIcon } from "@heroicons/react/24/outline";
import { UserPermission } from "apollo/cache/auth";

export type RouteProps = Omit<Route, "children"> & {
  withPermissions?: UserPermission[];
  navigation?: boolean;
  sidebar?: { label: string; icon: any }
  children?: RouteProps[]
}

const routes: RouteProps[] = [
  {
    path: "/",
    element: <DashboardPage />,
    sidebar: {
      label: "Dashboard",
      icon: ChartBarSquareIcon
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Dashboard",
      section: "General",
    },
  },
  {
    path: "regularization",
    element: <RegularizationPage />,
    sidebar: {
      label: "Regularizations",
      icon: UsersIcon
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Regularizations",
      section: "Customer Relation",
    },
    // withPermissions: ["*:*", "service-requests:*", "service-requests:manage"]
  },
  {
    path: "new-regularization",
    element: <CreateRegularizationPage />,
    sidebar: {
      label: "New Regularization",
      icon: UsersIcon
    },
    meta: {
      layout: "App",
      breadcrumb: () => "New Regularization",
      section: "Customer Relation",
    },
    // withPermissions: ["*:*", "service-requests:*", "service-requests:manage"]
  },
  {
    path: "shep-regularization",
    element: <ShepRegularizationPage />,
    sidebar: {
      label: "SHEP Capture",
      icon: UsersIcon
    },
    meta: {
      layout: "App",
      breadcrumb: () => "SHEP Capture",
      section: "Customer Relation",
    },
    // withPermissions: ["*:*", "service-requests:*", "service-requests:manage"]
  },
  {
    path: "new-shep-regularization",
    element: <CreateShepRegularizationPage />,
    sidebar: {
      label: "New SHEP Capture",
      icon: UsersIcon
    },
    meta: {
      layout: "App",
      breadcrumb: () => "New SHEP Capture",
      section: "Customer Relation",
    },
    // withPermissions: ["*:*", "service-requests:*", "service-requests:manage"]
  },

  {
    path: "forgot-password",
    element: <ForgotPasswordPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Forgot Password"
    }
  },
  {
    path: "signin",
    element: <SigninPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Signin"
    }
  },
  {
    path: "auth",
    element: <AuthPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Auth"
    }
  },
]

export default routes;
