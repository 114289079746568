import { TextInput, UploadArea } from "components/core";
import { useFormik } from "formik";
import { FC } from "react";
import { wrapClick, classNames } from "utils";
import { RadioGroup, Switch } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { ICreateRegularizationFormSchema, BillingInfoSchema } from "./schema";
import { NeighbourhoodClasses } from "apollo/data";

interface BillingInfoFormProps {
  handleNext: (values: ICreateRegularizationFormSchema["billingInfo"]) => void;
  handlePrevious: () => void;
  initialValues: ICreateRegularizationFormSchema["billingInfo"];
  values: ICreateRegularizationFormSchema;
  handleCancel: () => void;
}

const BillingInfoForm: FC<BillingInfoFormProps> = ({
  initialValues,
  handleNext,
  handlePrevious,
  handleCancel,
}) => {
  const form = useFormik<ICreateRegularizationFormSchema["billingInfo"]>({
    initialValues,
    validationSchema: BillingInfoSchema,
    onSubmit: (values) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  return (
    <form
      onSubmit={form.handleSubmit}
      className="flex-1 flex flex-col overflow-hidden"
    >
      <div className="space-y-6 divide-y divide-gray-200 p-4 md:p-6 flex-1 overflow-y-auto">
        <div>
          <span className="text-xs font-light">Service Information</span>
          <div className="grid grid-cols-6 gap-6 mt-2">
            <div className="col-span-6">
              <RadioGroup
                value={form.values.neighbourhoodClass}
                onChange={form.handleChange("neighbourhoodClass")}
              >
                <RadioGroup.Label className="text-sm font-medium text-gray-700">
                  Neighbourhood Class
                </RadioGroup.Label>
                <div className="mt-1 grid grid-cols-2 gap-x-4 gap-y-4 sm:gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                  {NeighbourhoodClasses.map((neighbourhoodClass) => (
                    <RadioGroup.Option
                      key={neighbourhoodClass.value}
                      value={neighbourhoodClass.value}
                      className={({ checked, active }) =>
                        classNames(
                          checked ? "border-transparent" : "border-gray-300",
                          active
                            ? "border-primary-500 ring-2 ring-primary-500"
                            : "",
                          "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
                        )
                      }
                    >
                      {({ checked, active }) => (
                        <>
                          <span className="flex flex-1">
                            <span className="flex flex-col">
                              <RadioGroup.Label
                                as="span"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {neighbourhoodClass.title}
                              </RadioGroup.Label>
                              <RadioGroup.Description
                                as="span"
                                className="mt-1 flex items-center text-sm text-gray-500"
                              >
                                {neighbourhoodClass.description}
                              </RadioGroup.Description>
                            </span>
                          </span>
                          <CheckCircleIcon
                            className={classNames(
                              !checked ? "invisible" : "",
                              "h-5 w-5 text-primary-600"
                            )}
                            aria-hidden="true"
                          />
                          <span
                            className={classNames(
                              active ? "border" : "border-2",
                              checked
                                ? "border-primary-500"
                                : "border-transparent",
                              "pointer-events-none absolute -inset-px rounded-lg"
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>

            <div className="col-span-3">
              <TextInput
                id="numberOfRooms"
                label="Number of rooms"
                type="number"
                step={1}
                min={1}
                placeholder="eg. 3"
                required={true}
                {...form}
              />
            </div>

            <div className="col-span-3">
              <TextInput
                id="numberOfMonths"
                label="Number of months"
                type="number"
                step={1}
                min={6}
                placeholder="eg. 3"
                required={true}
                {...form}
              />
            </div>
          </div>
        </div>
        <div className="pt-6">
          <span className="text-xs font-light">
            Energy Certification Information
          </span>
          <div className="grid grid-cols-6 gap-4 md:gap-6 mt-2">
            {/* <div className="col-span-6">
              <Switch.Group as="div">
                <span className="flex flex-grow flex-col">
                  <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                    Has Meter
                  </Switch.Label>
                </span>
                <div className="flex items-center justify-between mt-1  h-[38px]">
                  <Switch.Description as="span" className="text-sm text-gray-500">
                    Is there a meter installed at premise?
                  </Switch.Description>
                  <Switch
                    checked={form.values.hasMeter}
                    onChange={(val: boolean) => form.setFieldValue("hasMeter", val)}
                    className={classNames(
                      form.values.hasMeter ? "bg-primary-600" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        form.values.hasMeter ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>

                </div>
              </Switch.Group>
            </div> */}

            {form.values.hasMeter && (
              <>
                <div className="col-span-6 md:col-span-2">
                  <TextInput
                    id="meterNumber"
                    label="Meter Number"
                    type="text"
                    placeholder="eg. GH034034034"
                    disabled
                    required={true}
                    {...form}
                  />
                </div>
                <div className="col-span-6 md:col-span-4">
                  <UploadArea
                    id="meterImageUrl"
                    label="Meter Image"
                    required={true}
                    accept={{
                      "image/*": [".png", ".jpeg", ".jpg"],
                    }}
                    {...form}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="bg-gray-50 px-4 py-3 sm:py-4 sm:px-6 flex flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(form.handleSubmit)}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="hidden  w-full md:inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default BillingInfoForm;
