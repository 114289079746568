import { FC } from "react";
import { classNames } from "utils";
import {
  CheckBadgeIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { Avatar } from "components";
import { useReactiveVar } from "@apollo/client";
import { currentUserVar } from "apollo/cache/auth";
import { Link } from "react-location";

const actions = [
  {
    title: "Regularization",
    href: "/regularization",
    icon: UsersIcon,
    description: "Regularization is meant for capturing data on Non-SHEP customers who are not in ECG database mostly as a result of using foreign meters. The process involves the generation of Account Numbers and SPNs as well as a surcharge amount for stealing power.",
    iconForeground: "text-sky-700",
    iconBackground: "bg-sky-50",
  },
  {
    title: "SHEP Capturing",
    href: "/shep-regularization",
    icon: CheckBadgeIcon,
    description: "This portal is designed for the capturing of Shep customers on the field for onward billing",
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50",
  },
];

const DashboardPage: FC = () => {
  const currentUser = useReactiveVar(currentUserVar);

  return (
    <div className="bg-gray-100 h-full overflow-hidden flex flex-col">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-6">
        <div className="flex items-center">
          <Avatar
            alt={[
              currentUser?.firstName || "U",
              currentUser?.lastName || "A",
            ].join(" ")}
            size="md"
            src={currentUser?.profileImageUrl}
          />
          <div>
            <div className="flex items-center">
              <h1 className="ml-3 text-primary-600 text-xl font-extralight leading-7  sm:truncate sm:leading-9">
                Welcome back{" "}
                <span className="font-semibold">{currentUser?.firstName}</span>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 flex flex-col">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-6">
          <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
            {actions.map((action, actionIdx) => (
              <div
                key={action.title}
                className={classNames(
                  actionIdx === 0
                    ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none"
                    : "",
                  actionIdx === 1 ? "sm:rounded-tr-lg" : "",
                  actionIdx === actions.length - 2 ? "sm:rounded-bl-lg" : "",
                  actionIdx === actions.length - 1
                    ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none"
                    : "",
                  "group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"
                )}
              >
                <div>
                  <span
                    className={classNames(
                      action.iconBackground,
                      action.iconForeground,
                      "inline-flex rounded-lg p-3 ring-4 ring-white"
                    )}
                  >
                    <action.icon className="h-6 w-6" aria-hidden="true" />
                  </span>
                </div>
                <div className="mt-8">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    <Link to={action.href} className="focus:outline-none">
                      {/* Extend touch target to entire panel */}
                      <span className="absolute inset-0" aria-hidden="true" />
                      {action.title}
                    </Link>
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    {action.description}
                  </p>
                </div>
                <span
                  className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
                  aria-hidden="true"
                >
                  <svg
                    className="h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                  </svg>
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
