import { FC, PropsWithChildren } from "react";
import { Router } from "react-location";
import routes from "./routes";
import location from "./location";
import filterRoutes from "./filter";

const RoutesProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Router location={location} routes={routes} filterRoutes={filterRoutes()}>
      {children}
    </Router>
  );
};

export default RoutesProvider;
