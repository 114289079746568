import { FC } from "react";
import { ICreateShepRegularizationFormSchema } from "./schema";
import { ICurrentConfig } from "apollo/cache/config";
import moment from "moment";
import _ from "lodash";
import { wrapImage } from "utils";

const IdentityInfoSummary: FC<{
  data: ICreateShepRegularizationFormSchema["identityInfo"];
  values: ICreateShepRegularizationFormSchema;
  config: ICurrentConfig;
}> = ({ data, values, config: { dateFormat } }) => (
  <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
    {values?.customerInfo?.customerType === "Individual" && (
      <>
        <div className=''>
          <span className='text-xs font-light'>
            Customer Identity Information
          </span>
          <div className='grid grid-cols-6 gap-4 md:gap-6 mt-2'>
            <div className='col-span-6 md:col-span-2'>
              <span className='block text-sm font-light text-gray-700'>
                Has Ghana Card
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {data?.representative?.hasGhanaCard ? "Yes" : "No"}
              </div>
            </div>
            {!data?.representative?.hasGhanaCard && (
              <div className='col-span-6 md:col-span-2'>
                <span className='block text-sm font-light text-gray-700'>
                  ID Type
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {_.startCase(data?.representative?.identityCardType) || "N/A"}
                </div>
              </div>
            )}
          </div>
        </div>
        {data?.representative?.hasGhanaCard ? (
          <div className='pt-6'>
            <span className='text-xs font-light'>
              Customer Identity Information
            </span>
            <div className='grid grid-cols-6 gap-4 md:gap-6 mt-2'>
              <div className='col-span-6 md:col-span-2'>
                <span className='block text-sm font-light text-gray-700'>
                  Ghana Card Number
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {data?.representative?.ghanaCardNumber || "N/A"}
                </div>
              </div>
              <div className='col-span-3 md:col-span-2'>
                <span className='block text-sm font-light text-gray-700'>
                  Ghana Card Issue Date
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {data?.representative?.ghanaCardIssueDate
                    ? moment(data?.representative?.ghanaCardIssueDate).format(
                        dateFormat
                      )
                    : "N/A"}
                </div>
              </div>
              <div className='col-span-3 md:col-span-2'>
                <span className='block text-sm font-light text-gray-700'>
                  Ghana Card Expiry Date
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {data?.representative?.ghanaCardExpiryDate
                    ? moment(data?.representative?.ghanaCardExpiryDate).format(
                        dateFormat
                      )
                    : "N/A"}
                </div>
              </div>
              <div className='col-span-6 md:col-span-3'>
                <span className='block text-sm font-light text-gray-700'>
                  Ghana Card Front Image
                </span>
                {data?.representative?.ghanaCardFrontImageUrl ? (
                  <div className='mt-2'>
                    {wrapImage(
                      <img
                        src={data?.representative?.ghanaCardFrontImageUrl}
                        alt={"front"}
                        className='w-full h-64 text-xs'
                      />
                    )}
                  </div>
                ) : (
                  <div className='border-2 border-dashed border-gray-300 text-gray-600 flex items-center justify-center h-64 rounded-md mt-2'>
                    <span>Not Provided</span>
                  </div>
                )}
              </div>
              <div className='col-span-6 md:col-span-3'>
                <span className='block text-sm font-light text-gray-700'>
                  Ghana Card Back Image
                </span>
                {data?.representative?.ghanaCardBackImageUrl ? (
                  <div className='mt-2'>
                    {wrapImage(
                      <img
                        src={data?.representative?.ghanaCardBackImageUrl}
                        alt={"back"}
                        className='w-full h-64 text-xs'
                      />
                    )}
                  </div>
                ) : (
                  <div className='border-2 border-dashed border-gray-300 text-gray-600 flex items-center justify-center h-64 rounded-md mt-2'>
                    <span>Not Provided</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className='pt-6'>
            <span className='text-xs font-light'>
              Customer Identity Information
            </span>
            <div className='grid grid-cols-6 gap-4 md:gap-6 mt-2'>
              <div className='col-span-6 md:col-span-2'>
                <span className='block text-sm font-light text-gray-700'>
                  ID Number
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {data?.representative?.identityCardNumber || "N/A"}
                </div>
              </div>
              <div className='col-span-6 md:col-span-2'>
                <span className='block text-sm font-light text-gray-700'>
                  ID Issue Date
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {moment(data?.representative?.identityCardIssueDate).format(
                    dateFormat
                  )}
                </div>
              </div>
              {data?.representative?.identityCardType !==
                "VotersIdentificationCard" && (
                <div className='col-span-3 md:col-span-2'>
                  <span className='block text-sm font-light text-gray-700'>
                    ID Expiry Date
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {moment(
                      data?.representative?.identityCardExpiryDate
                    ).format(dateFormat)}
                  </div>
                </div>
              )}
              <div className='col-span-3'>
                <span className='block text-sm font-light text-gray-700'>
                  ID Front Image
                </span>
                <div className='mt-2'>
                  {wrapImage(
                    <img
                      src={data?.representative?.identityCardFrontImageUrl}
                      alt={"front"}
                      className='w-full h-64 text-xs'
                    />
                  )}
                </div>
              </div>
              {data?.representative?.identityCardType === "DriversLicense" && (
                <div className='col-span-3'>
                  <span className='block text-sm font-light text-gray-700'>
                    ID Back Image
                  </span>
                  <div className='mt-2'>
                    {wrapImage(
                      <img
                        src={data?.representative?.identityCardBackImageUrl}
                        alt={"back"}
                        className='w-full h-64 text-xs'
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </>
    )}
    {values?.customerInfo?.customerType === "Organization" && (
      <div className=''>
        <span className='text-xs font-light'>
          Organization Identity Information
        </span>
        <div className='grid grid-cols-6 gap-4 md:gap-6 mt-2'>
          <div className='col-span-6 md:col-span-2'>
            <span className='block text-sm font-light text-gray-700'>
              Tax Identification Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {data?.organization?.taxIdentificationNumber || "N/A"}
            </div>
          </div>
          <div className='col-span-6 md:col-span-2'>
            <span className='block text-sm font-light text-gray-700'>
              Organization Registration Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {data?.organization?.organizationRegistrationNumber || "N/A"}
            </div>
          </div>
          <div className='col-span-6 md:col-span-2'>
            <span className='block text-sm font-light text-gray-700'>
              Organization Registration Date
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {moment(data?.organization?.organizationRegistrationDate).format(
                dateFormat
              )}
            </div>
          </div>
          <div className='col-span-6 md:col-span-3'>
            <span className='block text-sm font-light text-gray-700'>
              Certificate of Incorporation
            </span>
            {data?.organization?.certificateOfIncorporationDocumentUrl ? (
              <div className='mt-2'>
                {wrapImage(
                  <img
                    src={
                      data?.organization?.certificateOfIncorporationDocumentUrl
                    }
                    alt={"Certificate of Incorporation"}
                    className='w-full h-64 text-xs'
                  />
                )}
              </div>
            ) : (
              <div className='border-2 border-dashed border-gray-300 text-gray-600 flex items-center justify-center h-64 rounded-md mt-2'>
                <span>Not Provided</span>
              </div>
            )}
          </div>
          <div className='col-span-6 md:col-span-3'>
            <span className='block text-sm font-light text-gray-700'>
              Business Registration Document
            </span>
            {data?.organization?.organizationRegistrationDocumentUrl ? (
              <div className='mt-2'>
                {wrapImage(
                  <img
                    src={
                      data?.organization?.organizationRegistrationDocumentUrl
                    }
                    alt={"Business Regsitration Document"}
                    className='w-full h-64 text-xs'
                  />
                )}
              </div>
            ) : (
              <div className='border-2 border-dashed border-gray-300 text-gray-600 flex items-center justify-center h-64 rounded-md mt-2'>
                <span>Not Provided</span>
              </div>
            )}
          </div>
        </div>
      </div>
    )}
    {values?.customerInfo?.customerType === "Organization" && (
      <>
        <div className='pt-6'>
          <span className='text-xs font-light'>
            Representative Identity Information
          </span>
          <div className='grid grid-cols-6 gap-4 md:gap-6 mt-2'>
            <div className='col-span-6 md:col-span-2'>
              <span className='block text-sm font-light text-gray-700'>
                Has Ghana Card
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {data?.representative?.hasGhanaCard ? "Yes" : "No"}
              </div>
            </div>
            {!data?.representative?.hasGhanaCard && (
              <div className='col-span-6 md:col-span-2'>
                <span className='block text-sm font-light text-gray-700'>
                  ID Type
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {_.startCase(data?.representative?.identityCardType) || "N/A"}
                </div>
              </div>
            )}
          </div>
        </div>
        {data?.representative?.hasGhanaCard ? (
          <div className='pt-6'>
            <span className='text-xs font-light'>
              Representative Identity Information
            </span>
            <div className='grid grid-cols-6 gap-4 md:gap-6 mt-2'>
              <div className='col-span-6 md:col-span-2'>
                <span className='block text-sm font-light text-gray-700'>
                  Ghana Card Number
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {data?.representative?.ghanaCardNumber || "N/A"}
                </div>
              </div>
              <div className='col-span-3 md:col-span-2'>
                <span className='block text-sm font-light text-gray-700'>
                  Ghana Card Issue Date
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {moment(data?.representative?.ghanaCardIssueDate).format(
                    dateFormat
                  )}
                </div>
              </div>
              <div className='col-span-3 md:col-span-2'>
                <span className='block text-sm font-light text-gray-700'>
                  Ghana Card Expiry Date
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {moment(data?.representative?.ghanaCardExpiryDate).format(
                    dateFormat
                  )}
                </div>
              </div>
              <div className='col-span-6 md:col-span-3'>
                <span className='block text-sm font-light text-gray-700'>
                  Ghana Card Front Image
                </span>
                <div className='mt-2'>
                  {wrapImage(
                    <img
                      src={data?.representative?.ghanaCardFrontImageUrl}
                      alt={"front"}
                      className='w-full h-64 text-xs'
                    />
                  )}
                </div>
              </div>
              <div className='col-span-6 md:col-span-3'>
                <span className='block text-sm font-light text-gray-700'>
                  Ghana Card Back Image
                </span>
                <div className='mt-2'>
                  {wrapImage(
                    <img
                      src={data?.representative?.ghanaCardBackImageUrl}
                      alt={"back"}
                      className='w-full h-64 text-xs'
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='pt-6'>
            <span className='text-xs font-light'>
              Representative Identity Information
            </span>
            <div className='grid grid-cols-6 gap-4 md:gap-6 mt-2'>
              <div className='col-span-6 md:col-span-2'>
                <span className='block text-sm font-light text-gray-700'>
                  ID Number
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {data?.representative?.identityCardNumber || "N/A"}
                </div>
              </div>
              <div className='col-span-3 md:col-span-2'>
                <span className='block text-sm font-light text-gray-700'>
                  ID Issue Date
                </span>
                <div className='mt-1 block w-full sm:text-sm'>
                  {moment(data?.representative?.identityCardIssueDate).format(
                    dateFormat
                  )}
                </div>
              </div>
              {data?.representative?.identityCardType !==
                "VotersIdentificationCard" && (
                <div className='col-span-3 md:col-span-2'>
                  <span className='block text-sm font-light text-gray-700'>
                    ID Expiry Date
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {moment(
                      data?.representative?.identityCardExpiryDate
                    ).format(dateFormat)}
                  </div>
                </div>
              )}
              <div className='col-span-6 md:col-span-3'>
                <span className='block text-sm font-light text-gray-700'>
                  ID Front Image
                </span>
                <div className='mt-2'>
                  {wrapImage(
                    <img
                      src={data?.representative?.identityCardFrontImageUrl}
                      alt={"front"}
                      className='w-full h-64 text-xs'
                    />
                  )}
                </div>
              </div>
              {data?.representative?.identityCardType === "DriversLicense" && (
                <div className='col-span-6 md:col-span-3'>
                  <span className='block text-sm font-light text-gray-700'>
                    ID Back Image
                  </span>
                  <div className='mt-2'>
                    {wrapImage(
                      <img
                        src={data?.representative?.identityCardBackImageUrl}
                        alt={"back"}
                        className='w-full h-64 text-xs'
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </>
    )}
  </div>
);

export default IdentityInfoSummary;
