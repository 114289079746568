import { useQuery, gql } from "@apollo/client"

const GET_TARIFF_CLASS = gql`
  query GetTariffClass($id: ID!) {
    tariffClass: getTariffClass(id: $id) {
      _id
      code
      name
    }
  }
`

const TariffInstancesBreadcrumb = ({id}: {id: string}) => {
  const { data } = useQuery(GET_TARIFF_CLASS, {
    variables: {
      id
    },
    notifyOnNetworkStatusChange: false
  })
  return (
    <>
      {data?.tariffClass?.name || "Tariff"}
    </>
  )
}

export default TariffInstancesBreadcrumb;